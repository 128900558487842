import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import ReactTooltip from 'react-tooltip';

const requestTooltipRebuild = _.debounce(() => ReactTooltip.rebuild(), 300);

class Tooltip extends ShallowRenderedComponent {

	static propTypes = {
		tip: PropTypes.any,
		html: PropTypes.bool
	};

	static defaultProps = {
		html: false
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
		requestTooltipRebuild();
	}

	componentDidUpdate() {
		requestTooltipRebuild();
	}

	render() {
		const { children, tip, html, place, className } = this.props;
		if(_.isEmpty(tip)) {
			return children;
		}
		const tipProps = {
			['data-tip']: tip,
			['data-multiline']: !html,
			['data-for']: html ? 'html-tooltip' : 'default-tooltip',
			['data-place']: place ? place : 'top',
			['data-class']: className,
			['className']: className
		};
		return React.isValidElement(children) ? React.cloneElement(children, tipProps) : <span {...tipProps}>{children}</span>;
	}
}

export default Tooltip;
