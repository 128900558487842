import _ from 'lodash';
import { normalizeDataWithValueKey } from '../core/chartsDataMappers';
import dateUtil from '../utils/dates';
import GlobalConst from '../utils/GlobalConst';
import { sliceCountryData, trafficSourcesTitles } from '../utils/func';
import { YTVideo, FlaggedVideo } from './VideoModel';
import { AWHistoricalData } from './AdWordsModels';

export class YTChannel {
	static fromJSON(json) {
		const getNodeSize = node => {
			if (_.isNumber(node.size)) {
				return node.size;
			}
			return _.reduce(node.children, (acc, child) => acc + getNodeSize(child), 0);
		};
		const primaryGenre = _.maxBy(json.genre, n => getNodeSize(n));
		let genData = json.general_data || {};
		let stats = json.stats || {};
		let customProperties = json.custom_properties || {};
		let iasData = json.ias_data || {};
		let iabCategories = genData.primary_category ? [genData.primary_category] : genData.iab_categories;
		return _.extend(new this(), {
			iasVerified: iasData.ias_verified,
			emails: customProperties.emails || [],
			blocked: customProperties.blocklist,
			tags: genData.video_tags || [],
			lastVideoPublishedAt: stats && stats.last_video_published_at ? dateUtil.dateToLocalZone(stats.last_video_published_at) : '',
			language: genData.top_language,
			languageCode: genData.top_lang_code,
			topKeywords: _.map(json.top_keywords, k => {
				return {
					category: k.category,
					videosCount: k.videos_count,
					keywords: _.map(k.keywords, keyword => {
						return { title: keyword.keyword, value: keyword.rate };
					})
				};
			}),
			publishedAt: genData.youtube_published_at ? dateUtil.dateToLocalZone(genData.youtube_published_at) : '',
			chartData: json.chart_data
				? {
						subscribers: normalizeDataWithValueKey(json.chart_data, 'subscribers', 'created_at', true),
						engagement: normalizeDataWithValueKey(json.chart_data, 'engage_rate', 'created_at', true),
						views: normalizeDataWithValueKey(json.chart_data, 'views', 'created_at', true),
						sentiment: normalizeDataWithValueKey(json.chart_data, 'sentiment', 'created_at', true)
				  }
				: {},
			safetyChartData: json.safety_chart_data,
			socialStats: {
				twitter: _.get(json, 'social.twitter_followers'),
				facebook: _.get(json, 'social.facebook_likes'),
				instagram: _.get(json, 'social.instagram_followers')
				// youtube: _.get(json, 'subscribers') // SAAS-1169
			},
			brand: json.brand,
			brandSafety: json.brand_safety,
			brandSafetyData: json.brand_safety_data,
			category: genData.top_category,
			iabCategories: iabCategories,
			primaryCategory: genData.primary_category,
			channelGroup: stats.channel_group,
			contentOwner: json.content_owner,
			country: genData.country,
			description: genData.description,
			details: json.details || '',
			engageRate: stats.engage_rate,
			externalLink: json.youtube_link || `https://www.youtube.com/channel/${json.main.id}`,
			id: json.main.id,
			performance: json.performance
				? {
						averageViews: json.performance.average_views,
						videos: _.map(json.performance.videos, video => YTVideo.fromJSON(video))
				  }
				: {},
			preferred: json.custom_properties ? json.custom_properties.preferred : null,
			hasAWData: json.ads_stats ? true : false,
			score: json.score
				? {
						engagement: json.score.engagement,
						content: json.score.content,
						momentum: json.score.momentum,
						sentiment: json.score.sentiment,
						reach: json.score.reach
				  }
				: {},
			sentiment: stats.sentiment,
			subscribers: stats.subscribers,
			thirtyDaysSubscribers: stats.last_30day_subscribers,
			views: stats.views,
			thirtyDaysViews: stats.last_30day_views,
			weeklyViews: stats.last_7day_views,
			dailyViews: stats.last_day_views,
			// thirtyDaysComments: json.thirty_days_comments,
			// weeklyComments: json.weekly_comments,
			// dailyComments: json.daily_comments,
			// comments: json.analytics ? json.analytics.comments || '' : '',
			thumbnailImageUrl: genData.thumbnail_image_url,
			title: genData.title,
			updatedAt: _.isEmpty(genData) ? dateUtil.dateToLocalZone(json.main.updated_at) : dateUtil.dateToLocalZone(genData.updated_at),
			viewsPerVideo: stats.views_per_video,
			youtubeKeywords: json.youtube_keywords || '',
			genre: json.genre,
			primaryGenre,
			audience: json.analytics
				? {
						ages: json.analytics.age ? Object.keys(json.analytics.age).map(title => ({ title: title, value: json.analytics.age[title] })) : {},
						countries: json.analytics.country ? sliceCountryData(json.analytics.country) : {},
						genders: json.analytics.gender ? Object.keys(json.analytics.gender).map(title => ({ title: title, value: json.analytics.gender[title], name: title })) : {},
						demographicItems: json.analytics.audience ? json.analytics.audience : {}
				  }
				: {},
			trafficSources:
				json.analytics && json.analytics.traffic_source
					? Object.keys(json.analytics.traffic_source).map(key => {
							return {
								source: key,
								title: trafficSourcesTitles[key],
								value: json.analytics.traffic_source[key]
							};
					  })
					: [],
			verified: json.analytics ? true : false,
			cmsTitle: json.analytics ? json.analytics.cms_title : null,
			socialLinks: {
				twitter: _.get(json, 'social.twitter_link'),
				facebook: _.get(json, 'social.facebook_link'),
				instagram: _.get(json, 'social.instagram_link')
			},
			adWordsData: json.ads_stats ? AWHistoricalData.fromJSON(json.ads_stats) : {},
			isOwner: json.is_owner,
			sourceViews: json.source_views,
			sources: {
				views: _.get('YouTube Data API'),
				viewsPerVideo: _.get('YouTube Data API'),
				thirtyDaysViews: _.get('YouTube Data API'),
				weeklyViews: _.get('YouTube Data API'),
				dailyViews: _.get('YouTube Data API'),
				subscribers: _.get('YouTube Data API'),
				thirtyDaysSubscribers: _.get('YouTube Data API'),
				socialStats: _.get('YouTube Data API'),
				ageGroup: _.get('Youtube Analytics API'),
				audience: _.get('Youtube Analytics API'),
				gender: _.get('Youtube Analytics API'),
				subscribersHistory: _.get('YouTube Data API'),
				viewsHistory: _.get('YouTube Data API'),
				trafficSources: _.get('Youtube Analytics API')
			},
			monetization: json.monetization,
			vettedState: json.vetted_status,
			isFlagged: !_.isEmpty(json.flags),
			languageDetectionData: !_.isEmpty(json.language_data) ? json.language_data : {}
		});
	}

	static toJSON(key, value) {
		switch (key) {
			case 'Brand':
				return {
					brand: value
				};
			case 'Content Owner':
				return {
					content_owner: value
				};
			case 'country':
				return {
					country: value.id
				};
			case 'channelGroup':
				return {
					channel_group: value.id
				};
			case 'socialLinks':
				const validateValue = value => (!_.isEmpty(value) ? value : null);
				return {
					social_links: {
						twitter: validateValue(value.twitter),
						facebook: validateValue(value.facebook),
						instagram: validateValue(value.instagram)
					}
				};
			default:
				return { [key]: value };
		}
	}

	// eslint-disable-next-line no-unused-vars
	getSourceDataTip(field) {
		//const { isAdminUser } = UserManager.user;
		// return isAdminUser && this.sources ? _.get(this, `sources.${field}`): '';
		return '';
	}

	get tagClass() {
		if (this instanceof YTChannel) {
			return 'yt-tag';
		}
		return '';
	}

	get source() {
		if (this instanceof YTChannel) {
			return GlobalConst.EXTERNAL_SOURCE_YOUTUBE;
		}
		return -1;
	}

	get isYoutube() {
		return this instanceof YTChannel;
	}
}

export class YTChannelItem {
	static fromJSON(json) {
		let genData = json.general_data || {};
		let stats = json.stats || {};
		return _.extend(new this(), {
			id: json.main.id,
			title: genData.title,
			description: json.description,
			subscribers: stats.subscribers,
			views: stats.views,
			videosCount: json.videos,
			thumbnailImageUrl: genData.thumbnail_image_url
		});
	}
}

export class YTChannelItemDetails {
	static fromJSON(json) {
		let genData = json.general_data || {};
		let stats = json.stats || {};
		let customProperties = json.custom_properties || {};
		return _.extend(new this(), {
			id: json.main.id,
			title: genData.title,
			publishedAt: genData.youtube_published_at && dateUtil.dateToLocalZone(genData.youtube_published_at),
			videos: _.map(json.videos, d => YTVideo.fromJSON(d)),
			contentOwner: json.content_owner,
			country: genData.country,
			description: genData.description,
			emails: customProperties.emails,
			commentsPerVideo: json.comments_per_video,
			lastVideoPublishedAt: json.last_video_published_at,
			likesPerVideo: json.likes_per_video,
			subscribers:stats.subscribers,
			tags: genData.video_tags || [],
			thumbnailImageUrl: genData.thumbnail_image_url,
			videosCount: json.videos_count,
			viewsPerVideo: stats.views_per_video,
			youtubeLink: json.youtube_link || `https://www.youtube.com/channel/${json.main.id}`,
			youtubePublishedAt: genData.youtube_published_at
		});
	}
}

export class YTTopChannelInfo {
	static fromJSON(json) {
		let genData = json.general_data || {};
		let stats = json.stats || {};
		return _.extend(new this(), {
			id: json.main.id,
			title: genData.title,
			subscribers: stats.subscribers,
			thumbnailImageUrl: genData.thumbnail_image_url,
			externalLink: json.url || `https://www.youtube.com/channel/${json.main.id}`,
			testimonialVideoID: json.video_id
		});
	}
}

export class YTChannelBrandSafety {
	static fromJSON(json) {
		return _.extend(new this(), {
			page: json.current_page,
			totalPages: json.max_page,
			brandSafety: json.brand_safety,
			badWords: json.brand_safety.flagged_words,
			flaggedVideos: _.map(json.items, video => FlaggedVideo.fromJSON(video)),
			videosCount: json.brand_safety.total_videos_scored,
			flaggedCount: json.brand_safety.total_flagged_videos
		});
	}
}

export class ChannelAuditFlags {
	static fromSingleJSON(json) {
		return {
			flagType: json?.flag_type,
			reason: json?.flag_reason,
			language: json?.expected_language
		};
	}

	static fromJSON(json) {
		const { flag_reasons } = json;
		return _.map(flag_reasons, item => ({ id: item.id, title: item.flag_reason }));
	}

	static toJSON(json) {
		const { flagType, reason, channelLanguage, language } = json;
		return {
			flag_type: flagType,
			flag_reason: reason,
			current_language: channelLanguage,
			expected_language: language
		};
	}
}
