import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../../base/ShallowRenderedComponent';
import { checkPureObject } from '../../../utils/func';

class GenericSelector extends ShallowRenderedComponent {

    static propTypes = {
        idKey: PropTypes.string,
        titleKey: PropTypes.string,
        multiselect: PropTypes.bool,
        selectionType: PropTypes.string,
        selections: PropTypes.any,
        onChange: PropTypes.func,
        id: PropTypes.string,
        options: PropTypes.any,
        className: PropTypes.string,
        allSelections: PropTypes.object,
		onUpdate: PropTypes.func,
        selectorTitle: PropTypes.string,
        showCountInTitle: PropTypes.bool,
        atLeastOne: PropTypes.bool
    }

    onCheckIfSelected = itemToCheck => {
        const { selectionType, idKey, multiselect, selections } = this.props;
        if (selectionType=='id'&&!multiselect) {
            return selections == itemToCheck[idKey];
        } else if (selectionType=='id2') {
            return selections == itemToCheck[idKey];
        } else if (selectionType=='id'&&multiselect) {
            let selectionsToCheck = _.isArray(selections) ? [ ...selections ] : [];
            return _.includes(selectionsToCheck, itemToCheck[idKey])
        } else if (selectionType=='object'&&multiselect) {
            return _.findIndex(selections, [idKey, itemToCheck[idKey]])>=0;
        } else if (selectionType=='object'&&!multiselect) {
            return selections[idKey] == itemToCheck[idKey];
        } else if (selectionType=='boolean') {
			return !!selections;
		}
    }

    onChange = itemsSelected => {
        const { selectionType, idKey, multiselect, selections, onChange, atLeastOne } = this.props;
		if (selectionType=='boolean'){
			onChange(itemsSelected);
		} else if (selectionType=='id'&&!multiselect) {
            if (selections==itemsSelected[idKey]) {
                if (atLeastOne) {
                    onChange(itemsSelected[idKey]);
                } else {
                    onChange(undefined);
                }
            } else {
                onChange(itemsSelected[idKey]);
            }
        } else if (selectionType=='id'&&multiselect) {
            let updatedSelections = _.isArray(selections) ? [ ...selections ] : [];
            if (_.includes(updatedSelections, itemsSelected[idKey])) {
                updatedSelections = _.filter(updatedSelections, item => item !== itemsSelected[idKey]);
            } else {
                updatedSelections = [ ...updatedSelections, itemsSelected[idKey]];
            }
            onChange(updatedSelections);
        } else if (selectionType=='id2') {
            if (selections==itemsSelected[idKey]) {
                onChange([undefined,undefined]);
            } else {
                onChange([itemsSelected[idKey], itemsSelected['id2']]);
            }
        } else if (selectionType=='file') {
            onChange(itemsSelected);
        } else if (selectionType=='object'&&!multiselect) {
            onChange(itemsSelected);
        } else if (selectionType=='object'&&multiselect) {
            let updatedSelections = _.isArray(selections) ? [ ...selections ] : [];
            if (_.findIndex(updatedSelections, [idKey, itemsSelected[idKey]])>=0) {
                updatedSelections = _.filter(updatedSelections, item => item[idKey] !== itemsSelected[idKey]);
            } else {
                updatedSelections = [ ...updatedSelections, itemsSelected];
            }
            onChange(updatedSelections);
        } else {
            onChange(itemsSelected);
        }
    }

    onSelectAll = () => {
        const { selectionType, idKey, selections, onChange, options } = this.props;
        if (selectionType=='id') {
            if (options?.length==selections?.length) {
                onChange([]);
            } else {
                let updatedSelections = _.map(options, item => item[idKey]);
                onChange(updatedSelections);
            }
        }
    }

    onSearch = value => {
        this.setState({ search: value });
    }

    onPopoverChange = (value) => {
        if (value) this.setState({ popoverOpened: true });
        else this.setState({ popoverOpened: null });
    }

    renderCheckbox = condition => {
        return condition ? <span className='material-icons' style={{ color: '#346abb' }}>check_box</span> :
            <span className='material-icons'>check_box_outline_blank</span>;
    }

    renderTitle = () => {
        const { selectorTitle, showCountInTitle, selections, titleBySelection, idKey, options, titleKey } = this.props;
        if (showCountInTitle&&selections) {
            let selectionsCount = 1;
            if (_.isArray(selections)) {
                selectionsCount = selections.length;
            } else if (checkPureObject(selections)) {
                selectionsCount = Object.keys(selections);
            }
            return <span className='ellipsis-overflow'>{selectorTitle} ({selectionsCount})</span>;
        } else if (titleBySelection) {
            const selectedItem = _.find(options, [idKey, selections[0]]);
            if (selectedItem?.icon) {
                return <div className='row-vertical-align of-auto'>
                    {selectedItem.icon}
                    <span className='ellipsis-overflow'>{!_.isUndefined(selectedItem) ? selectedItem[titleKey] : 'Option Selected'}</span>
                </div>;
            }
            return <span className='ellipsis-overflow'>{!_.isUndefined(selectedItem) ? selectedItem[titleKey] : 'Option Selected'}</span>;
        }
		return <span className='ellipsis-overflow'>{selectorTitle}</span>;
	}

}

export default GenericSelector;
