import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';

class PopoverHelper extends ShallowRenderedComponent {

	static propTypes = {
        id: PropTypes.string,
        show: PropTypes.bool,
        closePopover: PropTypes.func
	};

	constructor(props) {
		super(props);
	}

    componentDidMount() {
        const { closePopover, id } = this.props;
        document.body.addEventListener('click', function (event) {
			const popoverHelper = document.getElementById(id);
			const checkRecursively = (offsetParent, id) => {
				if (offsetParent?.id==id) return true;
				if (!_.isEmpty(offsetParent?.offsetParent)) {
					return checkRecursively(offsetParent?.offsetParent, id);
				}
				return false;
			}
            if (!popoverHelper?.contains(event.target) && !checkRecursively(event.target.offsetParent, id) && event.target.offsetParent !== null) {
                closePopover(id);
            }
        });
    }

	render() {
		const { id, show, children } = this.props;
		return <div id={id} className={cx('popover-helper', {'display-none':!show})}>
            {_.map(children, child => child)}
        </div>;
	}
}

export default PopoverHelper;
